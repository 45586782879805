import { TabBar, VStack, Text, Flex, Ellipsis, Box } from '@revolut/ui-kit'
import { getKpiPerformanceGraph } from '@src/api/kpis'
import { BaseChartInner } from '@src/components/Charts/BaseChart/BaseChartInner'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { KpiInterface } from '@src/interfaces/kpis'
import React, { useState } from 'react'

export const TargetsCharts = () => {
  const { values } = useLapeContext<GoalsInterface>()
  const targets = values.kpis
  const [selected, setSelected] = useState<KpiInterface | undefined>(targets[0])

  const maxTabWidth = 150

  return (
    <VStack space="s-16">
      <Box width="100%" overflow="scroll">
        <TabBar
          variant="segmented fit"
          value={String(selected?.id)}
          onChange={id => id && setSelected(targets.find(t => String(t.id) === id))}
        >
          {targets.map(t => (
            <TabBar.Item to={String(t.id)} key={t.id}>
              <Text variant="h6" maxWidth={maxTabWidth}>
                <Ellipsis tooltip="auto">{t.name}</Ellipsis>
              </Text>
            </TabBar.Item>
          ))}
        </TabBar>
      </Box>

      <VStack height="100%" mt="s-16">
        {selected && (
          <Flex
            flexDirection="column"
            minHeight={0}
            style={{ position: 'relative' }}
            height={{ all: 570, md: 400, sm: 250 }}
          >
            <BaseChartInner fetchData={getKpiPerformanceGraph} id={selected.id} />
          </Flex>
        )}
      </VStack>
    </VStack>
  )
}
