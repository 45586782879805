import { HStack, Token, VStack, Text, Caption, VisuallyHidden } from '@revolut/ui-kit'
import React, { PropsWithChildren } from 'react'
import { PersistentExpandableCell } from './PersistentExpandableCell'
import { KpiInterface } from '@src/interfaces/kpis'
import { useFormObserver } from '../FormObserverProvider'
import { useSubmitFlowHelpers } from '../../../common/utils'
import { kpisRequests } from '@src/api/kpis'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { captureException } from '@sentry/react'
import { InfoIconWithTooltip } from '@src/components/Icon/InfoIconWithTooltip'
import { checkFormErrors } from './helpers'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'

export const TargetCell = ({
  target,
  children,
  allowDelete,
  ...cellProps
}: PropsWithChildren<{
  allowDelete: boolean
  expanded: boolean
  onToggle: () => void
  target: KpiInterface
  index: number
}>) => {
  const { values } = useLapeContext<GoalsInterface>()
  const { getFormById } = useFormObserver()
  const form = getFormById(target.id)
  const { confirmationDialog, confirm, showSuccess, showError } = useSubmitFlowHelpers()
  const { unregisterForm } = useFormObserver()

  const prettifyUnit = (unit?: string) => {
    if (unit?.includes('score')) {
      return 'score'
    }
    if (unit?.includes('tickets')) {
      return 'tickets'
    }

    return unit
  }

  const getReviewCycle = (
    formValues: KpiInterface | undefined,
    fallback: KpiInterface,
  ) => {
    const data = formValues || fallback
    return data.targets.length
      ? data.targets[0].review_cycle?.name
      : data.target_epics?.[0]?.review_cycle.name
  }

  const handleDeleteClicked = allowDelete
    ? async () => {
        const confirmed = await confirm({
          body: `Please, confirm you want to delete ${target.name} target`,
          yesMessage: 'Delete',
          yesBtnVariant: 'negative',
          noMessage: 'Cancel',
          variant: 'compact',
        })

        if (confirmed.status === 'confirmed') {
          try {
            await kpisRequests.deleteItem(target.id)
            showSuccess('Target successfully deleted')
            values.kpis = values.kpis.filter(kpi => kpi.id !== target.id)
            unregisterForm(target.id)
          } catch (err) {
            captureException(err)
            showError('Something went wrong', 'Please try again')
          }
        }
      }
    : undefined

  const formValues = form?.values
  const formTarget = formValues?.targets?.[0]
  const hasFormErrors = checkFormErrors(form)
  const initialValue = formTarget?.initial_value ?? target.initial_value
  const targetValue = formTarget?.target ?? target.target
  const unit = formValues?.unit ?? target.unit
  const title = formValues?.name || target.name
  const description = getReviewCycle(formValues, target)

  const side = (
    <HStack gap="s-16" align="center">
      <VStack>
        <Text variant="secondary">
          {initialValue} {prettifyUnit(unit)}
        </Text>
        <Caption color={Token.color.greyTone50}>Initial</Caption>
      </VStack>
      <VStack>
        <Text variant="secondary">
          {targetValue} {prettifyUnit(unit)}
        </Text>
        <Caption color={Token.color.greyTone50}>Target</Caption>
      </VStack>
      {hasFormErrors && (
        <InfoIconWithTooltip content="This target is invalid" color={Token.color.red} />
      )}
    </HStack>
  )

  return (
    <>
      <PersistentExpandableCell
        {...cellProps}
        side={side}
        title={title}
        description={description}
        onDelete={handleDeleteClicked}
      >
        {children}
      </PersistentExpandableCell>
      <VisuallyHidden>
        <LapeNewInput name="targets" />
      </VisuallyHidden>
      {confirmationDialog}
    </>
  )
}
