import { DetailsCell, HStack, Icon, TabBar, Text, Token, VStack } from '@revolut/ui-kit'
import React, { useState } from 'react'
import { DashboardsList } from '../Widgets/Dashboards/DashboardsList'
import { GoalDashboardDetails, GoalPreviewInterface } from '@src/interfaces/goals'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DashboardPreview } from '../GoalForm'

export const AnalyticsPreviewWidget = ({
  onSelected,
}: {
  onSelected: (dash: GoalDashboardDetails) => void
}) => {
  const { values } = useLapeContext<GoalPreviewInterface>()
  const [tab, setTab] = useState<'chart' | 'table'>('chart')
  const [selected, setSelected] = useState<GoalDashboardDetails | undefined>(
    values.dashboards[0],
  )

  return (
    <DetailsCell>
      <DetailsCell.Title>
        <HStack space="s-8">
          <Icon name="ViewGrid" color={Token.color.greyTone20} />
          <Text variant="h6" color={Token.color.greyTone50}>
            Analytics
          </Text>
        </HStack>
      </DetailsCell.Title>
      <DetailsCell.Content>
        <TabBar variant="segmented" value={tab} onChange={t => t && setTab(t)}>
          <TabBar.Item to="chart">
            <Text lineHeight="secondary" use="div" mx="s-8">
              <Icon name="BarChart" size={14} />
            </Text>
          </TabBar.Item>
          <TabBar.Item to="table">
            <Text lineHeight="h2" use="div" mx="s-8">
              <Icon name="16/ListBullet" size={14} />
            </Text>
          </TabBar.Item>
        </TabBar>
      </DetailsCell.Content>
      <DetailsCell.Note>
        {tab === 'table' ? (
          <DashboardsList viewMode onViewRequested={onSelected} />
        ) : (
          <VStack space="s-16">
            <TabBar
              variant="segmented fit"
              value={String(selected?.id)}
              onChange={id =>
                id && setSelected(values.dashboards.find(t => String(t.id) === id))
              }
            >
              {values.dashboards.map(t => (
                <TabBar.Item to={String(t.id)} key={t.id}>
                  <Text variant="h6">{t.name}</Text>
                </TabBar.Item>
              ))}
            </TabBar>
            <VStack height="100%" mt="s-16" minHeight={500}>
              {selected && <DashboardPreview dashboard={selected} minHeight={500} />}
            </VStack>
          </VStack>
        )}
      </DetailsCell.Note>
    </DetailsCell>
  )
}
