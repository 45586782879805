import { DetailsCell, Icon, Token, Text, HStack, TabBar, Box } from '@revolut/ui-kit'
import React, { useState } from 'react'
import { KpiInterface } from '@src/interfaces/kpis'
import { TargetsList } from '../Widgets/Targets/TargetsList'
import { TargetsCharts } from './TargetsCharts'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalPreviewInterface } from '@src/interfaces/goals'

export const TargetsPreviewWidget = ({
  onSelected,
}: {
  onSelected: (target: KpiInterface) => void
}) => {
  const { values } = useLapeContext<GoalPreviewInterface>()
  const [tab, setTab] = useState<'chart' | 'table'>(
    values.kpis.length <= 3 ? 'chart' : 'table',
  )

  return (
    <DetailsCell>
      <DetailsCell.Title>
        <HStack space="s-8">
          <Icon name="TurboTransfer" color={Token.color.greyTone20} />
          <Text variant="h6" color={Token.color.greyTone50}>
            Targets
          </Text>
        </HStack>
      </DetailsCell.Title>
      <DetailsCell.Content>
        <TabBar
          variant="segmented"
          value={tab}
          onChange={selected => selected && setTab(selected)}
        >
          <TabBar.Item to="chart">
            <Text lineHeight="secondary" use="div" mx="s-8">
              <Icon name="BarChart" size={14} />
            </Text>
          </TabBar.Item>
          <TabBar.Item to="table">
            <Text lineHeight="h2" use="div" mx="s-8">
              <Icon name="16/ListBullet" size={14} />
            </Text>
          </TabBar.Item>
        </TabBar>
      </DetailsCell.Content>
      <DetailsCell.Note>
        {tab === 'table' ? (
          <Box mt="s-16">
            <TargetsList onSelected={onSelected} viewMode />
          </Box>
        ) : (
          <TargetsCharts />
        )}
      </DetailsCell.Note>
    </DetailsCell>
  )
}
